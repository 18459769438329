<template>
  <div class="container">
    <div class="page-header">
      <h1 data-test="label-articles" class="mb-0">Articles</h1>
    </div>

    <div class="d-flex flex-row-reverse align-items-center justify-content-between mb-3">
      <div class="d-flex justify-space-between flex-wrap gutter">
        <article-filters
          class="filter-container"
          :filterApplied="filterApplied"
          :selected="selectedFilters"
          @dirty="dirty => isFilterDirty = dirty"
          @input="items => setFilters(items)"
          @reset="setDefaultFilters(true)"
          @submit="applyFilters"/>
      </div>
      <div>
        <span
          v-if="isFilterDirty || query"
          data-test="txt-search-result"
          class="text-truncate">Results found {{numeral(meta.total).format('0,0')}}
          articles</span>

        <span
          v-else
          data-test="label-article-count"
          class="text-truncate">Showing {{numeral(items.length).format('0,0')}} of
          {{numeral(meta.total).format('0,0')}} total articles
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <dimmer :active="listLoading">
              <table data-test="table-articles" class="table table-hover table-outline table-vcenter card-table">
                <thead>
                  <tr>
                    <th>
                      <h-table-sorter :value="sortableFields.name" @sorting="direction => getSort(direction, 'name')">
                        Article
                      </h-table-sorter>
                    </th>
                    <th>
                      <h-table-sorter
                        :value="sortableFields.net_weight"
                        @sorting="direction => getSort(direction, 'net_weight')">
                        Net weight
                      </h-table-sorter>
                    </th>
                    <th>Quantity per net weight</th>
                    <th>Material type</th>
                    <th class="text-center">Status</th>
                    <th>
                      <h-table-sorter
                        :value="sortableFields.updated_at"
                        @sorting="direction => getSort(direction, 'updated_at')">
                        Last updated
                      </h-table-sorter>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td>
                      <div>
                        <router-link v-if="can(uiPermissions.ARTICLES_VIEW)" :to="`/articles/${item.id}`">{{item.name}}</router-link>
                        <span v-else>{{item.name}}</span>
                      </div>
                      <div class="small text-muted" style="white-space: normal">
                        <div>Rank: {{item.rank}}</div>
                        <div>Brand: {{(item.brand || {}).name || 'N/A'}}</div>
                      </div>
                    </td>
                    <td class="text-nowrap">
                      {{numeral(item.net_weight).format('0,0[.]00')}}
                      {{(item.net_weight_uom || {}).name}}
                    </td>
                    <td class="text-nowrap">
                      {{numeral(item.qty_per_net_weight).format('0,0[.]00')}}
                      {{(item.qty_per_net_weight_uom || {}).name}}
                    </td>
                    <td>{{getStartCase(item.material_type.name.split('-').join(' '))}}</td>
                    <td class="text-nowrap">
                      <div v-if="ArticleStatus[item.status]">
                        <i
                          class="fe fe-circle rounded-circle mr-1"
                          :class="ArticleStatus[item.status].class"></i>{{ArticleStatus[item.status].name}}
                      </div>
                      <div v-else>
                        <i
                          class="fe fe-circle rounded-circle b mr-1 bg-primary text-primary"></i>{{upperFirst(item.status)}}
                      </div>
                    </td>
                    <td class="text-nowrap">
                      {{moment(item.updated_at).fromNow()}}
                    </td>
                    <td class="text-center">
                      <div v-if="can([uiPermissions.ARTICLES_DUPLICATE, uiPermissions.ARTICLES_VIEW])" class="item-action dropdown">
                        <a
                          tabindex="0"
                          data-toggle="dropdown"
                          class="icon"><i class="fe fe-more-vertical"></i></a>
                        <div class="dropdown-menu dropdown-menu-right cursor-pointer">
                          <template v-if="item.status !== 'active' && can(uiPermissions.ARTICLES_APPROVE_STATUS_UPDATE)">
                            <button
                              type="button"
                              class="dropdown-item text-success"
                              @click="handleStatus(item.id, 'active')">
                              <i class="dropdown-icon fe fe-thumbs-up text-success"></i> Approve
                            </button>
                            <div class="dropdown-divider"></div>
                          </template>
                          <!-- Duplicate Article disabled until further notice -->
                          <button
                            v-if="can(uiPermissions.ARTICLES_DUPLICATE)"
                            type="button"
                            class="dropdown-item"
                            @click="handleDuplicate(item)">
                            <i class="dropdown-icon fe fe-copy"></i> Duplicate
                          </button>
                          <router-link
                            v-if="can(uiPermissions.ARTICLES_VIEW)"
                            :to="`/articles/${item.id}`"
                            class="dropdown-item">
                            <i class="dropdown-icon fe fe-edit"></i> Edit
                          </router-link>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="items.length === 0">
                    <td colspan="100%">There are no results matching your criteria.</td>
                  </tr>
                </tbody>
              </table>
            </dimmer>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleStatus from '@/assets/enums/ArticleStatus';
import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import {articles} from '@/services';
import submitting from '@hellochef/shared-js/mixins/submitting';
import ArticleFilters from '@/components/filters/ArticleFilters';
import {capitalize, isEmpty} from 'lodash';
import HTableSorter from '@/components/ui/HTableSorter';

export default {
  components: {
    ArticleFilters,
    Pagination,
    HTableSorter,
  },
  mixins: [
    list,
    submitting,
  ],
  data() {
    return {
      isFilterDirty: false,
      ArticleStatus,
      deleteArticleModalShow: false,
      deleteMessage: '',
      deleteRecipes: [],
      selectedFilters: {
        material_type_id: this.$route.query.material_type_id || '',
        status: this.$route.query.status || '',
      },
      sortableFields: {
        name: null,
        net_weight: null,
        updated_at: null,
      },
    };
  },
  computed: {
    filterApplied() {
      return !isEmpty(this.selectedFilters.material_type_id) || !isEmpty(this.selectedFilters.status);
    },
    filters() {
      // to remove a empty value property
      let qp = Object.fromEntries(Object.entries(this.selectedFilters).filter(([_, v]) => v !== ''));

      // retain if the query exists
      if (this.query) {
        qp = {
          ...qp,
          query: this.query,
        };
      }

      return qp;
    },
    sorts() {
      let sort = {};

      Object.keys(this.sortableFields).forEach(key => {
        const value = this.sortableFields[key];
        if (value) {
          sort = {
            column: key,
            direction: value,
          };
        }
      });

      return sort;
    },
  },
  methods: {
    fetchData(page) {
      return articles.getByParameters({
        limit: this.limit,
        page,
        query: this.query,
        ...this.filters,
        ...this.sorts,
        with: 'materialType,qtyPerNetWeightUom,netWeightUom',
      });
    },
    async handleDuplicate(item) {
      // create new and copy content
      this.$router.push(`/articles/new?duplicate=${item.id}`);
    },
    async handleStatus(id, status) {
      await articles.updateStatus(id, status);

      this.$store.dispatch('auth/restore');
      this.refresh();
    },
    getStartCase(text) {
      return capitalize(text);
    },
    setFilters(newValue) {
      Object.assign(this.selectedFilters, newValue);
    },
    setDefaultFilters() {
      this.selectedFilters = {
        material_type_id: '',
        status: '',
      };
    },
    applyFilters() {
      this.goToPage({page: 1});
      this.$router.replace({query: this.filters});
      this.refresh();
    },
    getSort(direction, field) {
      Object.keys(this.sortableFields).forEach(field => {
        this.sortableFields[field] = null;
      });

      this.sortableFields[field] = direction;

      this.refresh();
    },
  },
};
</script>

<style scoped>
.table thead th,
.text-wrap table thead th {
  vertical-align: middle;
}
</style>
