<template>
  <div>
    <div class="d-flex flex-wrap gutter align-items-center">
      <div class="actions">
        <button
          data-test="btn-filter-by"
          class="btn btn-secondary position-relative"
          @click="showFilter = true">
          <i class="fe fe-filter"></i> Filter by <i class="fe fe-chevron-down"></i>
          <span v-if="isDirty" class="position-absolute indicator"></span>
        </button>
      </div>
    </div>

    <b-modal
      v-model="showFilter"
      size="lg"
      title="Filter by"
      modal-class="modal-recipe-filters"
      hide-footer>
      <template slot="modal-header-close"><wbr/></template>
      <form @submit.prevent="applyFilters">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div
              class="form-group">
              <label class="form-label text-capitalize">Material Type</label>
              <select
                v-model="selected['material_type_id']"
                class="form-control custom-select"
                @change="($event)=>{
                  setValue($event.target.value, 'material_type_id')
                }">
                <option
                  disabled
                  selected
                  value>
                  Select material type
                </option>
                <option
                  v-for="(mType) in materialTypes"
                  :key="mType.id"
                  :value="mType.id">
                  {{formatMaterialType(mType.name)}}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-6">
            <div
              class="form-group">
              <label class="form-label text-capitalize">Status</label>
              <select
                v-model="selected['status']"
                class="form-control custom-select"
                @change="($event)=>{
                  setValue($event.target.value, 'status')
                }">
                <option
                  disabled
                  selected
                  value>
                  Select status
                </option>
                <option
                  v-for="(status, key) in statuses"
                  :key="key"
                  :value="key">
                  {{status.name}}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end pt-6">
          <button class="btn btn-link mr-2" @click.prevent="resetFilters">Reset</button>
          <button class="btn btn-primary" type="submit">Apply</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import FormTypes from '@/assets/enums/FormTypes';
import {materialTypes} from '@/services';
import {capitalize} from 'lodash';
import ArticleStatus from '@/assets/enums/ArticleStatus';

export default {
  name: 'ArticleFilters',
  components: {
  },
  props: {
    filterApplied: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      materialTypes: [],
      FormTypes,
      showFilter: false,
      isDirty: false,
      isReset: true,
    };
  },
  computed: {
    statuses() {
      const statuses = Object.assign({}, ArticleStatus);
      delete statuses.permanently_discontinued;
      return statuses;
    },
  },
  async created() {
    this.isDirty = this.filterApplied;
    const {items} = (await materialTypes.getByParameters({column: 'name', direction: 'asc'}));
    this.materialTypes = items;
  },
  methods: {
    resetFilters() {
      this.isReset = true;
      this.$emit('reset');

      this.isDirty = false;
      this.$emit('dirty', this.isDirty);
    },
    setValue(newValue, type) {
      this.$emit('input', {[type]: newValue});
      this.isReset = false;
    },
    applyFilters() {
      this.$emit('submit');
      this.showFilter = false;
      this.isDirty = !this.isReset;
      this.$emit('dirty', this.isDirty);
    },
    formatMaterialType(text) {
      return capitalize(text.split('-').join(' '));
    },
  },
};
</script>

  <style scoped>
    ::v-deep .modal-recipe-filters .modal-body {
      padding: 2rem;
    }

    .indicator {
      width: 0.7rem;
      aspect-ratio: 1;
      top: -5px;
      right: -5px;
      border-radius: 50%;
      background: #333;
    }

    .gutter {
      gap: 1rem;
    }
  </style>
